.w-pc-contacts-screen-2>.screen-wrapper{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 3vh;
}
.w-pc-contacts-screen-2>.screen-wrapper>img{
    height: 30vh;
}
.w-pc-contacts-screen-2>.screen-wrapper>h2 {
    width: 50vw;
    margin-block: 3vh;
    letter-spacing: 0.5px;
}
.w-pc-contacts-screen-2>.screen-wrapper>p{
    width: 50vw;
    margin-block: 2vh;
    letter-spacing: 0.5px;
}

/* mobile */
.w-mob-contacts-screen-2>.screen-wrapper {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 3vh;
}

.w-mob-contacts-screen-2>.screen-wrapper>img {
    height: 30vh;
}

.w-mob-contacts-screen-2>.screen-wrapper>h2 {
    width: 90vw;
    margin-block: 5px;
    margin-top: 50px;
    letter-spacing: 0.5px;
}

.w-mob-contacts-screen-2>.screen-wrapper>p {
    width: 90vw;
    margin-block: 10px;
    letter-spacing: 0.5px;
}


/* tab */
.w-tab-contacts-screen-2>.screen-wrapper {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 3vh;
}

.w-tab-contacts-screen-2>.screen-wrapper>img {
    height: 30vh;
}

.w-tab-contacts-screen-2>.screen-wrapper>h2 {
    width: 90vw;
    margin-block: 5px;
    margin-top: 50px;
    letter-spacing: 0.5px;
}

.w-tab-contacts-screen-2>.screen-wrapper>p {
    width: 90vw;
    margin-block: 10px;
    letter-spacing: 0.5px;
}