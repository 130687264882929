.w-pc-contacts-screen-1 {
    padding: 0;
    margin: 0;
    /* border: 1px dashed black; */
    background-image: url('../../assets/home-screen-bg-8.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: rgb(38, 38, 38); */
    color: #fff;
}

.w-pc-contacts-screen-1>.screen-wrapper {
    display: grid;
    grid-template-columns: 80% 20%;
    /* border: 0.5px black dashed; */
    padding-inline: 13vw;
    padding-block: 30vh;
    background-color: rgba(38, 38, 38, 0.553);
    color: #fff;

}

.w-pc-contacts-screen-1>.screen-wrapper>.wrapper-tagline {
    display: flex;
    flex-direction: column;
}

.w-pc-contacts-screen-1>.screen-wrapper>.wrapper-tagline>* {
    padding-block: 4px;
}

.w-pc-contacts-screen-1>.screen-wrapper>.wrapper-tagline>h1 {
    letter-spacing: 0.5px;
    font-weight: 500;
}

.w-pc-contacts-screen-1>.screen-wrapper>.wrapper-tagline>p {
    font-size: 8vh;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.w-pc-contacts-screen-1>.screen-wrapper>.wrapper-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.w-pc-contacts-screen-1>.screen-wrapper>.wrapper-button>p {
    padding-block: 15px;
    padding-inline: 25px;
    box-shadow: 2px 2px 2px rgba(75, 74, 74, 0.185);
    border-radius: 20px;
    font-size: 19px;
    background-color: rgb(184, 106, 69);
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
}

.w-pc-contacts-screen-1>.screen-wrapper>.wrapper-button>p:hover {
    background-color: rgb(59, 57, 57);
}


/* mobile */

.w-mob-contacts-screen-1 {
    padding: 0;
    margin: 0;
    /* border: 1px dashed black; */
    background-image: url('../../assets/home-screen-bg-8.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: rgb(38, 38, 38); */
    color: #fff;
}

.w-mob-contacts-screen-1>.screen-wrapper {
    display: grid;
    grid-template-columns: 80% 20%;
    /* border: 0.5px black dashed; */
    padding-inline: 13vw;
    padding-block: 30vh;
    background-color: rgba(38, 38, 38, 0.553);
    color: #fff;

}

.w-mob-contacts-screen-1>.screen-wrapper>.wrapper-tagline {
    display: flex;
    flex-direction: column;
}

.w-mob-contacts-screen-1>.screen-wrapper>.wrapper-tagline>* {
    padding-block: 4px;
}

.w-mob-contacts-screen-1>.screen-wrapper>.wrapper-tagline>h1 {
    letter-spacing: 0.5px;
    font-weight: 500;
}

.w-mob-contacts-screen-1>.screen-wrapper>.wrapper-tagline>p {
    font-size: 8vh;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.w-mob-contacts-screen-1>.screen-wrapper>.wrapper-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.w-mob-contacts-screen-1>.screen-wrapper>.wrapper-button>p {
    padding-block: 15px;
    padding-inline: 25px;
    box-shadow: 2px 2px 2px rgba(75, 74, 74, 0.185);
    border-radius: 20px;
    font-size: 19px;
    background-color: rgb(184, 106, 69);
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
}

.w-mob-contacts-screen-1>.screen-wrapper>.wrapper-button>p:hover {
    background-color: rgb(59, 57, 57);
}

/* tab */

.w-tab-contacts-screen-1 {
    padding: 0;
    margin: 0;
    /* border: 1px dashed black; */
    background-image: url('../../assets/home-screen-bg-8.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: rgb(38, 38, 38); */
    color: #fff;
}

.w-tab-contacts-screen-1>.screen-wrapper {
    display: grid;
    grid-template-columns: 80% 20%;
    /* border: 0.5px black dashed; */
    padding-inline: 13vw;
    padding-block: 300px;
    background-color: rgba(38, 38, 38, 0.553);
    color: #fff;

}

.w-tab-contacts-screen-1>.screen-wrapper>.wrapper-tagline {
    display: flex;
    flex-direction: column;
}

.w-tab-contacts-screen-1>.screen-wrapper>.wrapper-tagline>* {
    padding-block: 4px;
}

.w-tab-contacts-screen-1>.screen-wrapper>.wrapper-tagline>h1 {
    letter-spacing: 0.5px;
    font-weight: 500;
}

.w-tab-contacts-screen-1>.screen-wrapper>.wrapper-tagline>p {
    font-size: 50px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.w-tab-contacts-screen-1>.screen-wrapper>.wrapper-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.w-tab-contacts-screen-1>.screen-wrapper>.wrapper-button>p {
    padding-block: 15px;
    padding-inline: 25px;
    box-shadow: 2px 2px 2px rgba(75, 74, 74, 0.185);
    border-radius: 20px;
    font-size: 19px;
    background-color: rgb(184, 106, 69);
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
}

.w-tab-contacts-screen-1>.screen-wrapper>.wrapper-button>p:hover {
    background-color: rgb(59, 57, 57);
}