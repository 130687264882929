.w-pc-app-navbar{
    padding: 0;
    margin: 0;
    width: 100vw;

    display: grid;
    grid-template-columns: 10% 50% 40%;

    /* box-shadow: 0 3px 3px rgba(101, 101, 101, 0.162); */
    /* background-color: rgba(38, 38, 38, 0.354); */
        color: #fff;

    position: fixed;
    z-index: 9999;
    transition: 0.5s;
    padding-block: 1vh;

}
.w-pc-navbar-scrolled{
    box-shadow: 0 3px 3px rgba(101, 101, 101, 0.162);
    background-color: rgba(38, 38, 38, 0.354);
    backdrop-filter: blur(10px);
}

.w-pc-app-navbar>.anchor-group-1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.w-pc-app-navbar>.anchor-group-1>*{
    cursor: pointer;
}
.w-pc-app-navbar>.anchor-group-1>div {

    /* border-radius: 50%; */
    /* border: 1px solid red; */
    overflow: hidden;
}
.w-pc-app-navbar>.anchor-group-1>div>img{
    height: 50px;
    /* border-radius: 7px; */
    /* border-radius: 50%; */
}
    
.w-pc-app-navbar >.anchor-group-3 {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-right: 30px;
}
.w-pc-app-navbar>.anchor-group-3>p{
    /* margin-inline: 25px; */
    /* border: 1px solid red; */
    padding-inline: 20px;
    cursor: pointer;
    font-size: 16px;
}

.w-pc-app-navbar>div{
    /* border: 1px solid black; */
    padding-block: 0.8vh;
}



/* mobile */

.w-mob-app-navbar {
    padding: 0;
    margin: 0;
    width: 100vw;

    display: grid;
    grid-template-columns: 20% 60% 20%;

    /* box-shadow: 0 3px 3px rgba(101, 101, 101, 0.162); */
    /* background-color: rgba(38, 38, 38, 0.354); */
    color: #fff;

    position: fixed;
    z-index: 9999;
    transition: 0.5s;
    padding-block: 1vh;

}

.w-mob-navbar-scrolled {
    box-shadow: 0 3px 3px rgba(101, 101, 101, 0.162);
    background-color: rgba(38, 38, 38, 0.577);
    backdrop-filter: blur(20px);
}

.w-mob-app-navbar>.anchor-group-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.w-mob-app-navbar>.anchor-group-1>* {
    cursor: pointer;
}

.w-mob-app-navbar>.anchor-group-1>div {

    /* border-radius: 50%; */
    /* border: 1px solid red; */
    overflow: hidden;
}

.w-mob-app-navbar>.anchor-group-1>div>img {
    height: 50px;
    /* border-radius: 7px; */
    /* border-radius: 50%; */
}

.w-mob-app-navbar>.anchor-group-3 {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    /* padding-right: 30px; */
    padding-inline: 10px;
    /* background-color: red; */
}

.w-mob-app-navbar>.anchor-group-3>p {
    /* margin-inline: 25px; */
    /* border: 1px solid red; */
    padding-inline: 20px;
    cursor: pointer;
    font-size: 15px;
}

.w-mob-app-navbar>div {
    /* border: 1px solid black; */
    padding-block: 0.8vh;
    
}
.w-mob-app-navbar>.anchor-group-3>.burger-div>.img-burger{
    height: 55px;
    filter: invert(95%)
}
.w-mob-app-navbar>.anchor-group-3>.burger-div{
    padding: 0;
    /* padding-block: 10px; */
    /* padding-inline: 20px; */
    /* background-color: #fff; */
    /* border: 1px solid orange; */
    border-radius: 6px;
    color: rgb(54, 54, 54);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(47, 47, 47); */
}

.burger-collapsed{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.533);
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    box-sizing: border-box;
    padding-block: 25px;
    padding-inline: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.anchor-group-3-collapsed{
    height: 100%;
    width: 100%;
    background-color: #fff;
    color: rgb(81, 81, 81);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    box-sizing: border-box;
    padding-block: 0px;

}
.anchor-group-3-collapsed>p{
    margin-block: 6px;
    font-size: 19px;
    cursor: pointer;
    transition: 0.4s;
}
.anchor-group-3-collapsed>p:hover{
    color: rgb(149, 149, 149);
}
.anchor-group-3-collapsed>img{
    height: 100px;
    margin-block: 30px;
    cursor: pointer;
    border-radius: 50%;
}
.anchor-group-3-collapsed>.collapsed-close {
    /* border: 1px solid orange; */
    width: 100%;
    text-align: end;
    box-sizing: border-box;
    padding-inline: 10px;
    font-size: 35px;
    font-weight: 600;
    cursor: pointer;
}
.anchor-group-3-collapsed>h4{
    margin-bottom: 40px;
    font-size: 35px;
}

/* tab */

.w-tab-app-navbar {
    padding: 0;
    margin: 0;
    width: 100vw;

    display: grid;
    grid-template-columns: 10% 40% 50%;

    /* box-shadow: 0 3px 3px rgba(101, 101, 101, 0.162); */
    /* background-color: rgba(38, 38, 38, 0.354); */
    color: #fff;

    position: fixed;
    z-index: 9999;
    transition: 0.5s;
    padding-block: 1vh;

}

.w-tab-navbar-scrolled {
    box-shadow: 0 3px 3px rgba(101, 101, 101, 0.162);
    background-color: rgba(38, 38, 38, 0.354);
    backdrop-filter: blur(20px);
}

.w-tab-app-navbar>.anchor-group-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.w-tab-app-navbar>.anchor-group-1>* {
    cursor: pointer;
}

.w-tab-app-navbar>.anchor-group-1>div {

    /* border-radius: 50%; */
    /* border: 1px solid red; */
    overflow: hidden;
}

.w-tab-app-navbar>.anchor-group-1>div>img {
    height: 50px;
    /* border-radius: 7px;
    border-radius: 50%; */
}

.w-tab-app-navbar>.anchor-group-3 {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-right: 30px;
}

.w-tab-app-navbar>.anchor-group-3>p {
    /* margin-inline: 25px; */
    /* border: 1px solid red; */
    padding-inline: 17px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
}

.w-tab-app-navbar>div {
    /* border: 1px solid black; */
    padding-block: 0.8vh;
}

.w-tab-app-navbar>.anchor-group-3>.burger {
    padding: 0;
    padding-block: 10px;
    padding-inline: 20px;
    background-color: #fff;
    border-radius: 6px;
    color: rgb(54, 54, 54);
    font-size: 29px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}