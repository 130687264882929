.w-pc-app-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-block: 6vh;
    background-color: rgb(44, 44, 44);
    color: #fff;
}
.w-pc-app-footer>.footer-row-1>h2{
    padding-block: 6vh;
    font-size: 55px;
    cursor: pointer;
}
.w-pc-app-footer>.footer-row-2{
    display: flex;
    flex-direction: row;
}
.w-pc-app-footer>.footer-row-2>.footer-icon-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-inline: 20px;
}
.w-pc-app-footer>.footer-row-2>.footer-icon-group>*{
    margin-inline: 4px;
    cursor: pointer;
}
.w-pc-app-footer>.footer-row-2>.footer-icon-group>img {
    height: 15px;
    width: 15px;
    filter: invert(90%)
}
.w-pc-app-footer>.footer-row-2>.footer-icon-group>p{
    font-size: 15px;
}

/* mobile */

.w-mob-app-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-block: 6vh;
    background-color: rgb(44, 44, 44);
    color: #fff;
}

.w-mob-app-footer>.footer-row-1>h2 {
    padding-block: 35px;
    font-size: 37px;
    cursor: pointer;
}

.w-mob-app-footer>.footer-row-2 {
    display: flex;
    flex-direction: column;
}

.w-mob-app-footer>.footer-row-2>.footer-icon-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-inline: 20px;
    margin-block: 7px;
}

.w-mob-app-footer>.footer-row-2>.footer-icon-group>* {
    margin-inline: 4px;
    cursor: pointer;
}

.w-mob-app-footer>.footer-row-2>.footer-icon-group>img {
    height: 15px;
    width: 15px;
    filter: invert(90%);
}

.w-mob-app-footer>.footer-row-2>.footer-icon-group>p {
    font-size: 15px;
}



/* tab */

.w-tab-app-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-block: 6vh;
    background-color: rgb(44, 44, 44);
    color: #fff;
}

.w-tab-app-footer>.footer-row-1>h2 {
    padding-block: 3vh;
    font-size: 50px;
    cursor: pointer;
}

.w-tab-app-footer>.footer-row-2 {
    display: flex;
    flex-direction: row;
}

.w-tab-app-footer>.footer-row-2>.footer-icon-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-inline: 5px;
}

.w-tab-app-footer>.footer-row-2>.footer-icon-group>* {
    margin-inline: 4px;
    cursor: pointer;
}

.w-tab-app-footer>.footer-row-2>.footer-icon-group>img {
    height: 15px;
    width: 15px;
    filter: invert(90%)
}

.w-tab-app-footer>.footer-row-2>.footer-icon-group>p {
    font-size: 13px;
}


.w-pc-app-footer-floating-icon{
    position: fixed;
    right: 50px;
    bottom: 50px;
}
.w-pc-app-footer-floating-icon>img{
    height: 46px;
    width: 46px;
    transition: 0.3s;
    cursor: pointer;
}
.w-pc-app-footer-floating-icon>img:hover{
    filter: sepia(50%)
}

.w-tab-app-footer-floating-icon {
    position: fixed;
    right: 50px;
    bottom: 50px;
}
.w-tab-app-footer-floating-icon>img {
    height: 50px;
    width: 50px;
    transition: 0.3s;
    cursor: pointer;
}
.w-tab-app-footer-floating-icon>img:hover {
    filter: sepia(50%)
}


.w-mob-app-footer-floating-icon {
    position: fixed;
    right: 40px;
    bottom: 50px;
}
.w-mob-app-footer-floating-icon>img {
    height: 46px;
    width: 46px;
    transition: 0.3s;
    cursor: pointer;
}
.w-mob-app-footer-floating-icon>img:hover {
    filter: sepia(50%)
}