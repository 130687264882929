.w-pc-map-wrapper{
    height:70vh;
    width: 50vw;
    margin-block: 4vh;
    
}
.w-pc-map-wrapper>h2{
    margin-bottom: 3vh;
}


.w-tab-map-wrapper {
    height: 50vh;
    width: 90vw;
    margin-block: 4vh;

}

.w-tab-map-wrapper>h2 {
    margin-bottom: 3vh;
}


.w-mob-map-wrapper {
    height: 60vh;
    width: 95vw;
    margin-block: 4vh;

}

.w-mob-map-wrapper>h2 {
    margin-bottom: 3vh;
}