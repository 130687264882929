.w-pc-home-screen-2-tagline{
    padding: 0;
    margin: 0;
    /* border: 1px orange dashed; */
}
.w-pc-home-screen-2-tagline>.screen-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-inline: 5vw;
    padding-top: 10vh;
    padding-bottom:0vh;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>div{
    /* border: 0.5px orange dashed; */
    margin-block: 3vh;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company>p{
    margin-block: 5px;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company>.desc-title{
    font-size: 35px;
    font-weight: 600;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company>.desc-details {
    font-size: 22px;
    text-align: center;
}


.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>*{
    margin-block: 20px;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 40vh;
    width: 90%;
}

.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item {
    /* border: 0.5px green dashed; */
    box-sizing: border-box;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value{
    /* border: 1px dashed orange; */
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
    width: 85%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40% 60%;
    border-radius: 10px;

    box-shadow: 0px 0px 5px rgba(68, 67, 67, 0.32);
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value>*{
    padding: 0;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value>h3{
    font-size: 27px;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value>p {
    letter-spacing: 0.5px;
    font-size: 14px;
}
/* .w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 30vh;
    width: 100%;
}
.w-pc-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.card-core-value{
    border: 0.5px black dashed;
    box-sizing: border-box;
    padding: 20px;
} */


/* mobile */

.w-mob-home-screen-2-tagline {
    padding: 0;
    margin: 0;
    /* border: 1px orange dashed; */
}

.w-mob-home-screen-2-tagline>.screen-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-inline: 5vw;
    padding-top: 10vh;
    padding-bottom: 0vh;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>div {
    /* border: 0.5px orange dashed; */
    margin-block: 3vh;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company>p {
    margin-block: 5px;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company>.desc-title {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company>.desc-details {
    font-size: 17px;
    text-align: center;
}


.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>* {
    margin-block: 20px;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 40vh;
    width: 99%;
    row-gap: 30px;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item {
    /* border: 0.5px green dashed; */
    box-sizing: border-box;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value {
    /* border: 1px dashed orange; */
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
    width: 90%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 45% 55%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(68, 67, 67, 0.32);
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value>* {
    padding: 0;
    /* border: 1px solid red; */
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value>h3 {
    font-size: 3.5vh;
}

.w-mob-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value>p {
    letter-spacing: 0.5px;
    font-size: 1.9vh;
}



/* tab */

.w-tab-home-screen-2-tagline {
    padding: 0;
    margin: 0;
    /* border: 1px orange dashed; */
}

.w-tab-home-screen-2-tagline>.screen-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-inline: 0vw;
    padding-top: 10vh;
    padding-bottom: 0vh;
    /* border: 1px solid orange; */
}

.w-tab-home-screen-2-tagline>.screen-wrapper>div {
    /* border: 0.5px orange dashed; */
    margin-block: 2vh;
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company>p {
    margin-block: 7px;
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company>.desc-title {
    font-size: 35px;
    font-weight: 600;
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-desc-company>.desc-details {
    font-size: 22px;
    text-align: center;
}


.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* border: 1px solid orange; */
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>* {
    margin-block: 20px;
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 260px;
    row-gap: 30px;
    width: 90%;
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item {
    /* border: 0.5px green dashed; */
    box-sizing: border-box;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value {
    /* border: 1px dashed orange; */
    box-sizing: border-box;
    padding-block: 30px;
    padding-inline: 15px;
    height: 100%;
    width: 90%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 30% 70%;
    border-radius: 10px;

    box-shadow: 0px 0px 5px rgba(68, 67, 67, 0.32);
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value>* {
    padding: 0;
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value>h3 {
    font-size: 18px;
}

.w-tab-home-screen-2-tagline>.screen-wrapper>.wrapper-core-values>.cardbox-core-values>.grid-item>.card-core-value>p {
    letter-spacing: 0.5px;
    font-size: 13px;
}