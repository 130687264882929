.w-pc-home-screen-1-tagline{
    padding: 0;
    margin: 0;
    /* border: 1px dashed black; */
    background-image: url('../../assets/home-screen-bg-7.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(38, 38, 38, 0.354);
    color: #fff;
}

.w-pc-home-screen-1-tagline>.screen-wrapper{
    display: grid;
    grid-template-columns: 80% 20%;
    /* border: 0.5px black dashed; */
    padding-inline: 13vw;
    padding-block: 30vh;
    background-color: rgba(38, 38, 38, 0.354);
    color: #fff;
    
}
/* .w-pc-home-screen-1-tagline>.screen-wrapper>div{
    border: 0.5px red dashed;
} */
.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline{
    display: flex;
    flex-direction: column;
}
.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>*{
    padding-block:4px;
}
.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>.hashtag{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* border: 1px solid red; */

    margin-bottom: 23px;
}
.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>.hashtag>h2 {
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 60px;
    /* border: 1px solid yellow; */
}
.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>.hashtag>img {
    height: 65px;
    /* border: 1px solid yellow; */
}
.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>h1 {
    letter-spacing: 0.5px;
    font-size: 35px;
    font-weight: 500;
}
.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>p{
    font-size: 8vh;
    letter-spacing: 0.5px;
    font-weight: 500;
}


.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-button{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-button>p{
    padding-block: 15px;
    padding-inline:25px;
    box-shadow: 2px 2px 2px rgba(75, 74, 74, 0.185);
    border-radius: 20px;
    font-size: 19px;
    background-color: rgb(184, 106, 69);
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
}
.w-pc-home-screen-1-tagline>.screen-wrapper>.wrapper-button>p:hover{
    background-color: rgb(59, 57, 57);
}

/* mobile */
.w-mob-home-screen-1-tagline {
    padding: 0;
    margin: 0;
    /* border: 1px dashed black; */
    background-image: url('../../assets/home-screen-bg-7.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(38, 38, 38, 0.354);
    color: #fff;
}

.w-mob-home-screen-1-tagline>.screen-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    /* border: 1px orange dashed; */
    padding-inline: 5vw;
    padding-block: 30vh;
    background-color: rgba(38, 38, 38, 0.354);
    color: #fff;
    row-gap: 50px;

}

/* .w-mob-home-screen-1-tagline>.screen-wrapper>div{
    border: 0.5px red dashed;
} */
.w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline {
    display: flex;
    flex-direction: column;
}

.w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>* {
    padding-block: 4px;
}

/* .w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>h2 {
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 30px;
} */
 .w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>.hashtag {
     display: flex;
     align-items: center;
     /* justify-content: center; */
     /* border: 1px solid red; */

    margin-bottom: 29px;
 }
.w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>.hashtag>h2 {
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 40px;
}

.w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>.hashtag>img {
    height: 37px;
    /* border: 1px solid yellow; */
}

.w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>h1 {
    letter-spacing: 0.5px;
    font-size: 25px;
    font-weight: 500;
}

.w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>p {
    font-size: 54px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-button>p {
    padding-block: 15px;
    padding-inline: 25px;
    box-shadow: 2px 2px 2px rgba(75, 74, 74, 0.185);
    border-radius: 20px;
    font-size: 19px;
    background-color: rgb(184, 106, 69);
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
}

.w-mob-home-screen-1-tagline>.screen-wrapper>.wrapper-button>p:hover {
    background-color: rgb(59, 57, 57);
}





/* tab */
.w-tab-home-screen-1-tagline {
    padding: 0;
    margin: 0;
    /* border: 1px dashed black; */
    background-image: url('../../assets/home-screen-bg-7.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(38, 38, 38, 0.354);
    color: #fff;
}

.w-tab-home-screen-1-tagline>.screen-wrapper {
    display: grid;
    grid-template-columns: 70% 30%;
    /* border: 0.5px black dashed; */
    padding-inline: 9vw;
    padding-block: 30vh;
    background-color: rgba(38, 38, 38, 0.354);
    color: #fff;

}

/* .w-tab-home-screen-1-tagline>.screen-wrapper>div{
    border: 0.5px red dashed;
} */
.w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline {
    display: flex;
    flex-direction: column;
}

.w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>* {
    padding-block: 4px;
}

/* .w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>h2 {
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 39px;
    margin-bottom: 30px;
} */
 .w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>.hashtag {
     display: flex;
     align-items: center;
     /* justify-content: center; */
     /* border: 1px solid red; */

     margin-bottom: 30px;
 }

 .w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>.hashtag>h2 {
    letter-spacing: 0.5px;
        font-weight: 500;
        font-size: 39px;
 }

 .w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>.hashtag>img {
     height: 45px;
     /* border: 1px solid yellow; */
 }

.w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>h1 {
    letter-spacing: 0.5px;
    font-size: 30px;
    font-weight: 500;
}
.w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-tagline>p {
    font-size: 60px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-button>p {
    padding-block: 13px;
    padding-inline: 20px;
    box-shadow: 2px 2px 2px rgba(75, 74, 74, 0.185);
    border-radius: 20px;
    font-size: 15px;
    background-color: rgb(184, 106, 69);
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
}

.w-tab-home-screen-1-tagline>.screen-wrapper>.wrapper-button>p:hover {
    background-color: rgb(59, 57, 57);
}