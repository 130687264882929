.w-pc-catalogs-screen-1{
    padding: 0;
    margin: 0;
    /* height: 100vh; */
    box-sizing: border-box;
    /* background-color: blue; */
}

.w-pc-catalogs-screen-1>.screen-wrapper{
    padding-block: 10%;
    padding-inline: 3vw;
    box-sizing: border-box;
    height: 90%;
    /* border: 1px solid orange; */

    display: flex;
    flex-direction: column;
}



.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar{
    /* border: 1px solid red; */
    box-sizing: border-box;
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>h2 {
    width: 100%;
    /* border: 1px solid red; */
    text-align: center;
    padding-block: 10px;
    font-size: 40px;
    color: rgb(43, 43, 43);
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>input {
    border: 0.5px solid gray;
    border-radius: 50px;
    height: 3.5vh;
    width: 17vw;
    padding-block: 5px;
    padding-inline: 17px;
    font-size: 13px;
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu{
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-block: 2vh;
}

.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu>p{
    padding-inline: 0px;
    font-size: 13px;
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu>p:hover{
    color: gray;
    cursor: pointer;
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu>.linebar::after{
    content: "\007C";
    padding-inline: 10px;
}



.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox{
    margin-top: 15px;
    /* border: 1px solid green; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 320px));
    grid-auto-rows: 55vh;
    justify-content: center;
    /* overflow-y: scroll; */
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item{
    /* border: 1px solid red; */
    padding: 5%;
    box-sizing: border-box;

}   
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product{
    height: 100%;
    /* border: 0.5px solid green; */
    box-sizing: border-box;
    padding-top: 4%;
    border-radius: 6px;
    box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.271);
    transition: 0.5s;
    cursor: pointer;
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product:hover{
    /* box-shadow: 0px 0px 5px rgba(197, 130, 42, 0.67); */
    background-color: rgba(181, 151, 29, 0.195);
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-image{
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-image>img{
    height: 100%;
    border-radius: 6px;
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details{
    box-sizing: border-box;
    padding-inline: 5%;
    margin-top: 14px;
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details>p{
    font-size: 13px;
    margin-block: 5px;
    color: rgb(108, 108, 108);
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details>p>b {
    color: rgb(102, 102, 102);
}
.w-pc-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details>.card-name{
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}


.w-pc-navbar-additional{
    color: rgb(50, 50, 50);
}



/* mobile */
.w-mob-catalogs-screen-1 {
    padding: 0;
    margin: 0;
    /* height: 100vh; */
    box-sizing: border-box;
    /* background-color: blue; */
}

.w-mob-catalogs-screen-1>.screen-wrapper {
    padding-block: 70px;
    padding-inline: 20px;
    box-sizing: border-box;
    height: 90%;
    /* border: 1px solid orange; */

    display: flex;
    flex-direction: column;
}



.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar {
    /* border: 1px solid red; */
    box-sizing: border-box;
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>h2 {
    width: 100%;
    /* border: 1px solid red; */
    text-align: center;
    padding-block: 50px;
    font-size: 40px;
    color: rgb(43, 43, 43);
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>input {
    border: 0.5px solid gray;
    border-radius: 50px;
    height: 3.5vh;
    width: 200px;
    padding-block: 3px;
    padding-inline: 17px;
    font-size: 13px;
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-block: 2vh;
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu>p {
    padding-inline: 0px;
    font-size: 13px;
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu>p:hover {
    color: gray;
    cursor: pointer;
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu>.linebar::after {
    content: "\007C";
    padding-inline: 10px;
}



.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox {
    margin-top: 15px;
    /* border: 1px solid green; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 55vh;
    justify-content: center;
    /* overflow-y: scroll; */
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item {
    /* border: 1px solid red; */
    padding: 5%;
    box-sizing: border-box;

}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product {
    height: 100%;
    /* border: 0.5px solid green; */
    box-sizing: border-box;
    padding-top: 4%;
    border-radius: 6px;
    box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.271);
    transition: 0.5s;
    cursor: pointer;
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product:hover {
    /* box-shadow: 0px 0px 5px rgba(197, 130, 42, 0.67); */
    background-color: rgba(181, 151, 29, 0.195);
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-image {
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-image>img {
    height: 100%;
    border-radius: 6px;
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details {
    box-sizing: border-box;
    padding-inline: 5%;
    margin-top: 14px;
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details>p {
    font-size: 13px;
    margin-block: 5px;
    color: rgb(108, 108, 108);
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details>p>b {
    color: rgb(102, 102, 102);
}

.w-mob-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details>.card-name {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}


.w-mob-navbar-additional {
    color: rgb(50, 50, 50);
}
.w-mob-navbar-additional>.anchor-group-3>.burger-div>.img-burger{
    filter: invert(0%)
}



/* tab */
.w-tab-catalogs-screen-1 {
    padding: 0;
    margin: 0;
    /* height: 100vh; */
    box-sizing: border-box;
    /* background-color: blue; */
}

.w-tab-catalogs-screen-1>.screen-wrapper {
    padding-block: 70px;
    padding-inline: 20px;
    box-sizing: border-box;
    height: 90%;
    /* border: 1px solid orange; */

    display: flex;
    flex-direction: column;
}



.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar {
    /* border: 1px solid red; */
    box-sizing: border-box;
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>h2 {
    width: 100%;
    /* border: 1px solid red; */
    text-align: center;
    padding-block: 50px;
    font-size: 40px;
    color: rgb(43, 43, 43);
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>input {
    border: 0.5px solid gray;
    border-radius: 50px;
    height: 3.5vh;
    width: 200px;
    padding-block: 3px;
    padding-inline: 17px;
    font-size: 13px;
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-block: 2vh;
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu>p {
    padding-inline: 0px;
    font-size: 13px;
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu>p:hover {
    color: gray;
    cursor: pointer;
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-searchbar>.searchbar-menu>.linebar::after {
    content: "\007C";
    padding-inline: 10px;
}



.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox {
    margin-top: 15px;
    /* border: 1px solid green; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 320px));
    grid-auto-rows: 380px;
    justify-content: center;
    /* place-items: center; */
    /* overflow-y: scroll; */
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item {
    /* border: 1px solid red; */
    padding: 5%;
    box-sizing: border-box;

}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product {
    height: 100%;
    /* border: 0.5px solid green; */
    box-sizing: border-box;
    padding-top: 4%;
    border-radius: 6px;
    box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.271);
    transition: 0.5s;
    cursor: pointer;
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product:hover {
    /* box-shadow: 0px 0px 5px rgba(197, 130, 42, 0.67); */
    background-color: rgba(181, 151, 29, 0.195);
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-image {
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-image>img {
    height: 100%;
    border-radius: 6px;
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details {
    box-sizing: border-box;
    padding-inline: 5%;
    margin-top: 14px;
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details>p {
    font-size: 13px;
    margin-block: 5px;
    color: rgb(108, 108, 108);
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details>p>b {
    color: rgb(102, 102, 102);
}

.w-tab-catalogs-screen-1>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-product>.card-wrapper-details>.card-name {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}


.w-tab-navbar-additional {
    color: rgb(50, 50, 50);
}

.w-tab-navbar-additional>.anchor-group-3>.burger-div>.img-burger {
    filter: invert(0%)
}