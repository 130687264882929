.w-pc-home-screen-3-services {
    padding: 0;
    margin: 0;
    /* border: 1px dashed black; */
}
.w-pc-home-screen-3-services>.screen-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    padding-inline: 5vw;
    padding-top: 10vh;
    padding-bottom: 10vh;
}
.w-pc-home-screen-3-services>.screen-wrapper>*{
    margin-block: 3vh;
}



.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-desc{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
}
.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-desc>*{
    margin-block: 5px;
}
.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-desc>h3 {
    font-size: 35px;
    font-weight: 600;
}
.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-desc>p {
    font-size: 22px;
    text-align: center;
    
}


.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-cardbox{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* grid-auto-rows: 50vh; */
    width: 90%;
    /* border: 1px solid green; */
}
.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item{
    /* border: 1px solid orange; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service{
    /* border: 1px solid red; */
    /* height: 100%; */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}
.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>*{
    margin-block: 13px;
}

.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>img{
    height: 40vh;
    width: 40vh;
    /* border: 1px solid orange; */
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s;
}
.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>h3{
    font-size: 24px;
}
.w-pc-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>img:hover{
    filter: sepia(70%)
}


/* mobile */

.w-mob-home-screen-3-services {
    padding: 0;
    margin: 0;
    /* border: 1px dashed black; */
}

.w-mob-home-screen-3-services>.screen-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    padding-inline: 5vw;
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.w-mob-home-screen-3-services>.screen-wrapper>* {
    margin-block: 3vh;
}



.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
}

.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-desc>* {
    margin-block: 5px;
}

.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-desc>h3 {
    font-size: 37px;
    font-weight: 600;
}

.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-desc>p {
    font-size: 17px;
    text-align: center;
    margin-block: 10px;
}


.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-cardbox {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* grid-auto-rows: 50vh; */
    width: 90%;
    row-gap: 60px;
    /* border: 1px solid green; */
}

.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item {
    /* border: 1px solid orange; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service {
    /* border: 1px solid red; */
    /* height: 100%; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>* {
    margin-block: 13px;
}

.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>img {
    height: 250px;
    width: 250px;
    /* border: 1px solid orange; */
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s;
}

.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>h3 {
    font-size: 27px;
    text-align: center;
}

.w-mob-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>img:hover {
    filter: sepia(70%)
}



/* tab */

.w-tab-home-screen-3-services {
    padding: 0;
    margin: 0;
    /* border: 1px dashed black; */
}

.w-tab-home-screen-3-services>.screen-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    padding-inline: 5vw;
    padding-top: 50px;
    padding-bottom: 10vh;
}

.w-tab-home-screen-3-services>.screen-wrapper>* {
    margin-block: 3vh;
}



.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
}

.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-desc>* {
    margin-block: 0px;
}

.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-desc>h3 {
    font-size: 35px;
    font-weight: 600;
}

.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-desc>p {
    font-size: 18px;
    text-align: center;
    margin-block: 7px;
}


.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-cardbox {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* grid-auto-rows: 50vh; */
    width: 100%;
    /* border: 1px solid green; */
}

.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item {
    /* border: 1px solid orange; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service {
    /* border: 1px solid red; */
    /* height: 100%; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>* {
    margin-block: 13px;
}

.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>img {
    height: 250px;
    width: 250px;
    /* border: 1px solid orange; */
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s;
}

.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>h3 {
    font-size: 24px;
}

.w-tab-home-screen-3-services>.screen-wrapper>.wrapper-cardbox>.grid-item>.card-service>img:hover {
    filter: sepia(70%)
}